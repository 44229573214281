import React from "react";
import "../css/footer.css";
export default class Footer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }
  render() {
    return (
      <div>
        <section>
          <div className="footer">
            <div className="w-full flex items-center justify-center space pt-12 banner-color">
              <div className="container text-white flex flex-col">
                <div className="w-full flex flex-col lg:flex-row">
                  <h1 className="w-full text-7xl md:text-7xl uppercase font-bold mt-12 md:w-2/3 text-gray-400">
                    we never forget who we are working for
                  </h1>

                  <div className="content-icon-panel lg:self-center mt-8 lg:mt-0">
                    <div className="icon-content pl-0 lg:pl-24 flex flex-row space-x-8 lg:justify-between mb-4">
                      <span className="display-span">
                        <img
                          src="../w-location.png"
                          className="w-6 h-8 pt-2"
                        />
                      </span>
                      <span className="display-span self-center">
                        <a
                          target="_blank"
                          href="https://www.google.com/maps/place/13%C2%B003'54.8%22N+80%C2%B007'51.0%22E/@13.0655542,80.1307739,17z/data=!4m13!1m7!3m6!1s0x0:0x4a4c9bc4f3101d36!2zMTPCsDAzJzU0LjgiTiA4MMKwMDcnNTEuMCJF!3b1!8m2!3d13.0652221!4d80.1308334!3m4!1s0x0:0x4a4c9bc4f3101d36!8m2!3d13.0652221!4d80.1308334?hl=en"
                          className="paragraph text-gray-400 hover:text-yellow-400"
                        >Fitlane Sports,<br />2nd Floor, Pillaiyar Kovil Street,<br />Sir Padmavathi Nagar, Mathiravedu, <br />Thiruverkadu, Chennai - 99.
                        </a>
                      </span>
                    </div>

                    <div className="icon-content pl-0 lg:pl-24 flex flex-row space-x-8 mb-4">
                      <span className="display-span">
                        <img src="../w-mail.png" className="w-6 h-8 pt-2" />
                      </span>
                      <span className="display-span self-center">
                        <a
                          target="_blank"
                          href="mailto: business@fitlanesports.com"
                          className="paragraph text-gray-400 hover:text-yellow-400"
                        >
                          business@fitlanesports.com
                        </a>
                      </span>
                    </div>

                    <div className="icon-content pl-0 lg:pl-24 flex flex-row space-x-8 mb-4">
                      <span className="display-span">
                        <img src="../w-phone.png" className="w-6 h-8 pt-2" />
                      </span>
                      <span className="display-span self-center">
                        <a
                          target="_blank"
                          href="tel:+91 7448-32-33-34"
                          className="paragraph text-gray-400 hover:text-yellow-400"
                        >
                          +91 7448-32-33-34
                        </a>
                      </span>
                    </div>
                  </div>
                </div>
                {/* <div className="flex mt-10 flex-col md:flex-row">
                  <p className="w-full md:w-2/3 text-2xl text-gray-400 md:text-base lg:pb-0 pb-4">
                    An initiative by Fitlane Sports to help school management, parants monitor their child fitness and sports journey in this mobile app.
                  </p>
                  <div className="pl-0 lg:pl-24 lg:text-center mt-8 lg:mt-0">
                    <a className="bg-transparent hover:bg-yellow-400 text-center text-yellow-400 mt-8 lg:mt-0 hover:text-black rounded shadow hover:shadow-lg py-2 px-4 border border-yellow-400 hover:border-transparent uppercase btn-time">
                      Download from App Store</a>
                  </div>
                </div> */}
                <div className="flex flex-col">
                  <div className="flex mt-6 mb-6 flex-col lg:flex-row justify-between">
                    <div className="lg:place-self-center">
                      <a href="/" className="uppercase">
                        <span>
                          <img className="img-width" src="../full-w.png" />
                        </span>
                      </a>
                    </div>

                    <a
                      href="/about"
                      className="lg:m-0 m-2.5 lg:self-center md:block cursor-pointer text-gray-400 hover:text-yellow-400 uppercase"
                    >
                      About
                    </a>
                    <a
                      href="/service"
                      className="lg:m-0 m-2.5 lg:self-center md:block cursor-pointer text-gray-400 hover:text-yellow-400 uppercase"
                    >
                      Program
                    </a>
                    {/* <a
                      href="/gallery"
                      className="lg:m-0 m-2.5 lg:self-center md:block cursor-pointer text-gray-400 hover:text-yellow-400 uppercase"
                    >
                      Gallery
                    </a> */}
                    <a
                      href="/contact"
                      className="lg:m-0 m-2.5 lg:self-center md:block cursor-pointer text-gray-400 hover:text-yellow-400 uppercase"
                    >
                      Contact
                    </a>
                    <a
                      href="/career"
                      className="lg:m-0 m-2.5 lg:self-center md:block cursor-pointer text-gray-400 hover:text-yellow-400 uppercase"
                    >
                      Career
                    </a>

                    <div className="flex flex-row space-x-8 items-center lg:mt-0 mt-8 m-2.5">
                      {/* <a target="_blank" href="#">
                        <span className="display-span">
                          <img className="w-6 h-6 text-gray-400 hover:text-yellow-400" src="../whatsapp.png" />
                        </span>
                      </a> */}
                      <a target="_blank" href="https://www.linkedin.com/in/fitlane-sports-5413b322a/">
                        <span className="display-span">
                          <img className="w-6 h-6 text-gray-400 hover:text-yellow-400" src="../linkedin.png" />
                        </span>
                      </a>
                      <a target="_blank" href="https://www.facebook.com/profile.php?id=100077296112013">
                        <span className="display-span">
                          <img className="w-6 h-6 text-gray-400 hover:text-yellow-400" src="../facebook.png" />
                        </span>
                      </a>
                      <a target="_blank" href="https://www.instagram.com/fitlane__sports/">
                        <span className="display-span">
                          <img className="w-6 h-6 text-gray-400 hover:text-yellow-400" src="../instagram.png" />
                        </span>
                      </a>
                    </div>
                  </div>
                </div>
                <hr className="border-gray-600" />
                <p className="w-full lg:text-center py-6 text-lg text-gray-400 md:text-base">
                Copyright © 2022 Fitlane Sports. Developed by Adiv Technologies                  
                </p>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}
