import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { Navbar } from 'responsive-navbar-react'
import 'responsive-navbar-react/dist/index.css'

const NavBar = () => {
  const props = {
    items: [
      {
        text: 'Home',
        link: '/'
      },

      {
        text: 'About',
        link: '/about'
      },
      {
        text: 'program',
        link: '/service'
      },
      // {
      //   text: 'Gallery',
      //   link: '/gallery'
      // },
      {
        text: 'Contact',
        link: '/contact'
      },
      {
        text: 'Career',
        link: '/career'
      }
    ],
    logo: {
      img: '../b-logo.png',
      link: '/'
    },
    style: {
      barStyles: {
        background: 'rgb(255,255,255,0.3)',
        //background: 'radial-gradient(circle, rgba(181,169,41,1) 0%, rgba(147,42,11,0.9396709367340687) 26%)',
      },
      sidebarStyles: {
        // background: '#D4AF37',
        background: 'rgb(229 231 235)',
        buttonColor: 'black'
      },
    }
  }
  return (
    <div>
      
        <Navbar {...props} />
      
    </div>
  );
}
export default NavBar;