import React from "react";
import Footer from '../components/footer'
import "../css/career.css"
import NavBar from '../components/navbar'

// import Layout from "../components/layout"
import Seo from "../components/seo"

const NotFoundPage = () => (
  // <Layout>
  <div>
                <div>
                    <NavBar />
                </div>
                <div className="about-banner pt-12">
                </div>
                <div class="my-14 mx-auto container">
                  <h1 class="text-9xl text-center">404</h1>
                  <h3 class="text-5xl text-center uppercase">Page Not Found</h3>
                  <div class="mt-12 lg:text-center">
                    <a href="/" class="bg-transparent hover:bg-yellow-400 text-center text-yellow-400 mt-8 lg:mt-0 hover:text-black rounded shadow hover:shadow-lg py-2 px-4 border border-yellow-400 hover:border-transparent uppercase btn-time">
                      Back to Home</a>
                  </div>
                </div>
                <div>
                    <Footer />
                </div>
            </div>
  // </Layout>
)

export default NotFoundPage
